export default ({
  namespaced: true,
  state(){
    return {
     
     userdata:{
        username:'user',
        time:'0'
      },

    }
  },
  mutations: {
    pushuser(state,n){
        state.userdata.username = n.username
        state.userdata.time = n.time
      },
  },
  actions: {
  },
  modules: {
  },

})
