import { ref } from 'vue';

import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// import router from '../router/index';
import user from './modules/user.js';

export default createStore({
  state(){
    return {
      click:ref(3)
    }
  },
  mutations: {
    changeclick (state, n) {
      console.log(state);
      state.click = n.num
    },
    updatatime(state){
      // if (state.time!=0) {
        console.log(state);
      //   setTimeout(()=>{
      //     if (new Date().getTime()>state.goodtime+(1800*1000)) {
      //       this._mutations.init[0](state)
      //       router.push({ path:'/login'})
      //       alert('登录时间超时，请重新登录')
      //     }else{
      //       // console.log(state.time);
      //       state.time +=1
      //       this._mutations.updatatime[0](state)
      //     }
      //   },1000)
      // }
    },
  },
  actions: {
  },
  modules: {
    user
  },
  // 配置插件
  plugins: [
    createPersistedState({
      // 本地存储的名字
      key: 'chat',
      // 指定需要存储的模块
      paths: ['user']
    })
  ]
})
