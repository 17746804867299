<template>
  <div class="home">
   
  </div>
</template>

<script setup>
import router from '../router/index';

router.push({ path: '/login' })
</script>
<style>
.home{
  width: 100%;
}
.el-header {
  position: relative;
  background-color: #27323f;
  color:white;
  /* margin-bottom: 1rem; */
  height: 80px;
  padding: 0;
}
.el-aside {
  color:white;
  background-color: #304156;
}
.el-menu {
  border-right: none;
}
.el-main {
  padding: 0;
  background-color: #f0f0f0;
}
.toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 20px;
}
</style>