import 'element-plus/dist/index.css';
import './assets/css/common.css';
import 'normalize.css/normalize.css';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import { createApp } from 'vue';

import ElementPlus from 'element-plus';

import App from './App.vue';
import router from './router';
import store from './store';

createApp(App).use(store).use(router).use(ElementPlus, {
    locale: zhCn,
  }).mount('#app')
 